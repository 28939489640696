<template>
  <a-modal
    :confirm-loading="loading"
    title="创建站点"
    :visible="visible"
    @ok="handleOk"
    @cancel="() => { this.$refs.form.resetFields(); this.visible = false }"
  >
    <a-form-model ref="form" :label-col="{ span: 4, style: { textAlign: 'left' } }" :model="form" :rules="rules" :wrapper-col="{ span: 20 }">
      <a-form-model-item label="域名" prop="name">
        <a-input v-model="form.name" :max-length="128" placeholder="请输入主域名"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createDomain } from '@/api/domain'

export default {
  name: 'CreateForm',
  data () {
    return {
      loading: false,
      visible: false,
      form: {},
      rules: {
        name: [
          { message: '请输入主域名', required: true },
          { message: '请输入正确的主域名', pattern: /^[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?$/, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          createDomain(this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.visible = false
            this.$refs.form.resetFields()
          }).finally(() => { this.loading = false })
        }
      })
    }
  }
}
</script>
