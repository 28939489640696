<template>
  <div>
    <a-alert
      message="域名主要用于子域名的收集，方便添加站点。"
      :show-icon="true"
      type="info"
      style="margin-bottom: 24px;"
    ></a-alert>
    <a-card :bordered="false">
      <a-form-model :label-col="{ span: 4, style: { textAlign: 'left' } }" :model="form" :wrapper-col="{ span: 18 }">
        <a-row>
          <a-col :md="8" :sm="24">
            <a-form-model-item label="域名" prop="name">
              <a-input v-model="form.name" :allow-clear="true" :max-length="128" @pressEnter="() => { this.pagination.current = 1; this.fetch() }"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-model-item>
              <a-button icon="search" type="primary" @click="() => { this.pagination.current = 1; this.fetch() }">查询</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <a-button icon="plus" type="primary" @click="$refs.createForm.show()" style="margin-bottom: 24px;">创建</a-button>
      <create-form ref="createForm" @ok="fetch"></create-form>

      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        @change="(p, f, s) => { this.pagination.current = 1; this.sorter = s; this.fetch() }"
      >
        <template slot="state" slot-scope="state">
          <collect-state-tag :state="state"></collect-state-tag>
        </template>
        <template slot="operation" slot-scope="text, record">
          <template v-if="record.verified">
            <a-button size="small" type="link" @click="$router.push({ name: 'DomainDetail', params: { id: record.id } })">查看</a-button>
          </template>
          <template v-else>
            <a-button size="small" type="link" style="color: #faad14" @click="$refs.verifyModal.show()">认证</a-button>
            <verify-modal ref="verifyModal" :domain-id="record.id" @ok="fetch"></verify-modal>
          </template>
          <a-divider type="vertical"></a-divider>
          <a-popconfirm title="是否确定删除？" @confirm="handleDelete(record.id)">
            <a-button size="small" type="link" style="color: #f5222d">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { deleteDomain, getDomains } from '@/api/site'
import CollectStateTag from './modules/CollectStateTag'
import CreateForm from './modules/CreateForm'
import VerifyModal from './modules/VerifyModal'

export default {
  name: 'DomainList',
  components: { CollectStateTag, CreateForm, VerifyModal },
  data () {
    return {
      form: {},
      visible: false,
      columns: [
        { dataIndex: 'name', title: '域名', width: 240 },
        { dataIndex: 'updated_at', title: '更新时间', sorter: true, width: 200 },
        { dataIndex: 'subdomain_count', title: '子域名统计', align: 'center', width: 240 },
        { dataIndex: 'state', title: '收集状态', width: 240, scopedSlots: { customRender: 'state' } },
        { title: '操作', width: 160, fixed: 'right', align: 'center', scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      loading: false,
      pagination: { current: 1, pageSize: 20, total: 0 },
      sorter: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      const params = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      }
      if (this.sorter.field && this.sorter.order) {
        params.sort_by = this.sorter.field
        params.order_by = this.sorter.order
      }
      if (this.form.name) params.name = this.form.name
      getDomains(params).then(res => {
        this.dataSource = res.data
        this.pagination.total = res.total
      }).finally(() => { this.loading = false })
    },
    handleDelete (id) {
      deleteDomain(id).then(res => {
        this.$message.success(res.message)
        this.fetch()
      })
    }
  }
}
</script>
