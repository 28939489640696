<template>
  <a-modal
    :confirm-loading="loading"
    ok-text="验证"
    title="域名验证"
    :visible="visible"
    :width="720"
    @ok="handleOk"
    @cancel="() => { this.visible = false }"
  >
    <a-descriptions :column="1">
      <a-descriptions-item>1、请添加域名解析记录</a-descriptions-item>
      <a-descriptions-item>
        <a-table :columns="columns" :data-source="dataSource" :pagination="false" row-key="rR" size="small" ></a-table>
      </a-descriptions-item>
      <a-descriptions-item>2、请点击 <strong>验证</strong> 按钮进行验证（域名解析生效可能需要一段时间）；</a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script>
import { verifyDomain } from '@/api/domain'

export default {
  name: 'VerifyModal',
  props: {
    domainId: { type: String, required: true }
  },
  data () {
    return {
      loading: false,
      visible: false,
      columns: [
        { dataIndex: 'rR', title: '主机记录', width: 120 },
        { dataIndex: 'type', title: '记录类型', width: 120 },
        { dataIndex: 'value', title: '记录值' }
      ],
      dataSource: [{ rR: '_verify_dns', type: 'TXT', value: this.domainId.replaceAll('-', '') }]
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    handleOk () {
      this.loading = true
      verifyDomain(this.domainId).then(res => {
        this.$message.success(res.message)
        this.$emit('ok')
        this.visible = false
      }).finally(() => { this.loading = false })
    }
  }
}
</script>
